<template>
  <v-container id="login" class="fill-height justify-center" tag="section">
    <v-row style="max-width: 450px">
      <v-slide-y-transition appear>
        <!-- Login Card -->
        <v-card 
          class="pa-3 pa-md-5 mx-auto card" 
          v-bind:class="{ flipme: cardOne[0] == 'flipped' }"
          :dark="$vuetify.theme.dark" 
          
        >
          <!-- Flip icon-->
          <v-row 
            :class="cardOne[0] == 'flipped' ? 'card__face--back': ''">
            <v-tooltip top>
              
              <template v-slot:activator="{ on }">
                <v-hover v-slot:default="{ hover }">
                  <v-btn
                    icon
                    elevation="0"
                    @click.prevent="toggleCard"
                    class="ml-auto"
                    :color="hover ? 'primary' : ''"
                    v-on="on"
                  >
                    <v-icon>
                      mdi-flip-horizontal
                    </v-icon>
                  </v-btn>
                </v-hover>
              </template>
              <span>Flip card</span>
            </v-tooltip>
          </v-row>

          <!-- Logo row-->
          <v-row :class="cardOne[0] == 'flipped' ? 'card__face--back': ''">
            <v-img
              class="mx-auto mb-8"
              max-width="100%"
              :src="'/assets/branding/' + (($vuetify.theme.dark) ? 'poweredByDoohDark.png' : 'poweredByDoohLight.png')" 
            ></v-img>
          </v-row>

          <!-- Form row -->
          <v-row 
            v-if="cardOne[0] == 'start'"
            :class="cardOne[0] == 'flipped' ? 'card__face--back': ''"
            v-bind:class="{ flipme: cardOne[0] == 'flipped' }"
          >
            <v-col cols="12" md="12">
              <div class="text-center">
                <!-- Username -->
                <v-text-field
                  autofocus
                  color="primary"
                  label="Email"
                  id='username'
                  prepend-icon="mdi-email"
                  v-model="email"
                  @change='credentialsError = false'
                  type="email"
                  :error="credentialsError"
                  @keyup.enter="nextFocusByID('password')"
                />
                
                <!-- Password -->
                <v-text-field
                  color="primary"
                  label="Password"
                  id='password'
                  prepend-icon="mdi-lock-outline"
                  :append-icon=" showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  v-model="password"
                  @change='credentialsError = false'
                  :type=" showPassword ? 'text' : 'password'"
                  :error="credentialsError"
                  @click:append="showPassword = !showPassword"
                  @keyup.enter="loginUser"
                />
                
                <!-- Error popup -->
                <div class="ma-0 pa-0">
                  <span 
                    v-if="credentialsError" 
                    style="color: #ff5252"
                  >{{errorMessage}}</span>
                </div>

                <!-- Forgot password -->
                <div class="mb-4 text-center">
                  <a href="/#/password-reset-request">Forgot your password?</a>
                </div>

                <!-- ? -->
                <v-row>
                  <v-col cols="12" class="text-center">
                    <span style="color: #04B526;">{{$route.query.redirectMsg}}</span>
                  </v-col>
                </v-row>
                
                <!-- Login -->
                <v-btn 
                  id='loginBtn'
                  depressed
                  color="primary"
                  @click="loginUser"
                  :loading="loginPending"
                  :disabled="loginPending"
                >Login</v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row 
            v-if="cardOne[0] == 'flipped'" class="card__face--back"
          >
            <v-col cols="12" md="12">
              <div class="text-center">
                <!-- Password -->
                <v-text-field
                  color="primary"
                  label="Password"
                  id='opensesame'
                  prepend-icon="mdi-lock-outline"
                  :append-icon=" showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  v-model="doohSecret"
                  @change='credentialsError = false'
                  :type=" opensesame ? 'text' : 'password'"
                  :error="credentialsError"
                  @click:append="opensesame = !opensesame"
                  @keyup.enter="openPopGallery"
                />
                </div>
                <!-- Login -->
                <v-row style="width: 100%" class="justify-center my-4">
                  <v-btn 
                    id='loginBtn'
                    depressed
                    color="primary"
                    @click.prevent="openPopGallery"
                  >Explore</v-btn>
                </v-row>
              </v-col>
          </v-row>
        </v-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import signin from '@/mixins/auth/signin'
import manipulateDocument from '@/mixins/helpers/manipulateDocument'
import AuthenticationController from "@/services/controllers/Authentication";
import axios from "axios";
import store from '@/store'
import updateAllPermissions from '@/mixins/permissions/update'

// API
import UserController from '@/services/controllers/User'

export default {
  mixins: [manipulateDocument],

  data: () => ({
    email: null,
    password: null,
    showPassword: false,
    credentialsError: false,
    loginPending: false,
    errorMessage: "",
    // Flipped
    cardOne: {},
    opensesame: null,
    doohSecret: null,
  }),

  computed: {
    ...mapGetters('User',
      ['user', 'isAuthed']
    ),
  },
  created() {
    this.cardOne = ['start']
    // check if coming from route pop-gallery-land
    if(this.$route.query.land) {
      this.cardOne = ['flipped']
    }
  },
  watch: {
    opensesame: function (val) {
    }
  },
  methods: {

    
    async openPopGallery() {
      try{
        const data = {
            phrase: this.doohSecret
        }
        await AuthenticationController.loginUserPopGallery(data)
        .then((res) => {
            axios.defaults.headers.common = {
              Authorization: `bearer ${res.data.token}`
            };

            // Store locally
            localStorage.setItem("refreshToken", res.data.refreshToken);
            localStorage.setItem("username", res.data.email);

            // Store on Vuex
            store.dispatch('User/signIn', res.data)
            this.credentialsError = false;
            this.$router.push({ name: 'PopGallery' });
        })
      }catch(e){
        this.credentialsError = true;
        if(e.message)
          this.errorMessage = e.message;
      }
      /*
      if(this.opensesame == 'opensesame') {
        this.$router.push({ name: 'PopGallery' });
      } */
    },


    toggleCard() {
      if(this.cardOne[0] == 'start'){
        this.$set(this.cardOne, 0, 'flipped')
      } else {
        this.$set(this.cardOne, 0, 'start')
      }
    },
    async loginUser() {
      this.loginPending= true
      const response = await signin(this.email, this.password)
      if(response.status === 200) {
        this.credentialsError = false;
        const res2 = await UserController.getUserSetting('NightMode')
        localStorage.setItem('isDarkMode', res2.data.value)
        this.$vuetify.theme.dark = res2.data.value
        this.$router.push(this.$route.query.redirectFrom || { name: 'Campaigns' });
        this.loginPending= false
        // get user permissions
        await updateAllPermissions()
      } else  {
        this.loginPending= false
        this.credentialsError = true;
        if(!response.data)
        {
          this.errorMessage = "Backend not running";
        }
        else if(response.data.message)
          this.errorMessage = response.data.message;
      }
    },

  },
};
</script>

<style scoped>
/* this style is applied when the card is clicked */
.flipme {
  transform: rotateY(180deg);
}
.card {
  transition: transform 0.7s;
  transform-style: preserve-3d;
}
.card__face {
  align-items: start;
  backface-visibility: hidden;
}
.card__face--back {
  transform: rotateY(180deg);
}
</style>